<template>
  <el-col v-if="buttonList.length>0" :span="24" class="toolbar">
    <!-- btn列表 -->
    <el-button-group v-if="buttonList.length>1">
      <!-- 这里触发点击事件 -->
      <el-button
        v-for="item in buttonList"
        :key="item.Id"
        :value="item.Name"
        type="primary"
        @click="callFunc(item)"
        size="mini"
        plain
        :icon="item.Icon"
        round
      >{{item.Name}}</el-button>
    </el-button-group>
    <el-button
      v-else
      v-for="item in buttonList"
      :key="item.Id"
      :value="item.Name"
      type="primary"
      @click="callFunc(item)"
      size="mini"
      plain
      :icon="item.Icon"
      round
    >{{item.Name}}</el-button>
  </el-col>
</template>
<script>
export default {
  name: "Toolbar",
  data () {
    return {
      searchVal: "", //双向绑定搜索内容
      showVal: true,
      searchmsg: null,
    };
  },
  props: ["buttonList"], //接受父组件传值
  methods: {
    callFunc (item) {
      item.search = this.searchVal;
      this.$emit("callFunction", item); //将值传给父组件
    }
  },
  mounted () {

  }
};
</script>