<template>
  <section>
    <!-- <el-form :inline="true" size="mini" style="padding-left:10px;margin-bottom:-15px">
      <el-form-item label="职务名称:">
        <el-input v-model="postSearch" placeholder="请输入职务名称" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" plain @click="postFind">查询</el-button>
      </el-form-item>
    </el-form>-->
    <div id="posttree">
      <el-tree
        :load="loadPostNode"
        :props="PostProps"
        @node-click="PostClick"
        lazy
        v-if="datatree"
        highlight-current
        :data="postsearchdata"
      >
        <span class="custom-tree-node" slot-scope=" {node,data} ">
          <span>
            <img v-if="data.Type == '职务'" src="../assets/user.png" alt />
            <img v-else src="../assets/users.png" alt />
            {{ node.label }}
          </span>
        </span>
      </el-tree>
    </div>
  </section>
</template>
<script>
import { GetPostChildrenList, GetPostList, GetManagePost } from '../api/hr';
export default {
  name: "Postchoose",
  data () {
    return {
      datatree: true,   //树重新加载 
      PostProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      },
      postSearch: null,
      postsearchdata: []
    }
  },
  props: ["department"], //接受父组件传值
  methods: {
    //组织维护新增职务选择树节点加载
    loadPostNode (node, resolve) {
      if (node.level === 0) {
        this.loadChoosePostfirstnode(resolve);
      }
      if (node.level >= 1 && node.isLeaf == false) {
        this.loadPostchildNode(node, resolve);
      }
    },
    //组织维护新增职务选择加载第一级节点
    loadChoosePostfirstnode (resolve) {
      let para = { departmentId: this.department };
      GetManagePost(para).then((res) => {
        if (res.data.response != null) {
          var myArray = new Array();
          myArray[0] = res.data.response;
          this.postsearchdata = myArray;
        } else {
          this.postdata = null;
        }
      });
    },
    //职务维护加载节点的子节点集合
    loadPostchildNode (node, resolve) {
      if (0 < node.level && node.isLeaf == false) {
        let para = { postId: node.data.Id };
        GetPostChildrenList(para).then((res) => {
          const roledata = res.data.response;
          resolve(roledata);
        });
      }
    },
    //组织维护职务查询
    postFind () {
      let para = { name: this.postSearch };
      GetPostList(para).then((res) => {
        var myArray = new Array();
        for (var i = 0; i < res.data.response.length; i++) {
          myArray.push(res.data.response[i]);
        }
        this.postsearchdata = myArray;
      });
    },
    //节点点击
    PostClick (node) {
      this.$emit("postcallFunction", node); //将值传给责任人
      // this.datatree = false;
    }
  },
  watch: {   //监听值变化
    department: function () {
      this.datatree = false;
      this.$nextTick(() => {
        this.datatree = true;
      });
    }
  }
}
</script>
<style scoped>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fabe64;
}
#posttree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 540px);
  /*width:200px;*/
  border: 0px solid blue;
}
</style>