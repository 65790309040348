<template>
  <section style="background-color: #ffff">
    <!--顶层工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="buttonListmsg"
    ></toolbar>
    <!--组织维护树形工具条-->
    <el-col :span="6">
      <div class="orgbtndivtree">
        <el-row >
          <ToolbarButtonGroupMini
            :buttonList="buttonListDpt"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>部门</el-divider>
        <div class="orgtree">
          <el-tree
            :load="loadDptChildNode"
            :props="DptProps"
            :expand-on-click-node="false"
            @node-click="dptTreeNodeClick"
            lazy
            highlight-current
            :indent="30"
            node-key="Id"
            ref="tree1"
            :data="departmentsearchdata"
            :render-content="renderContent"
          ></el-tree>
        </div>
      </div>
    </el-col>
    <!--职务维护树形工具条-->
    <el-col :span="6">
      <div class="orgbtndivtree">
        <el-row>
          <ToolbarButtonGroupMini
            :buttonList="buttonListPost"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>职务</el-divider>
        <div class="orgtree">
          <el-tree
            :data="postdata"
            :load="loadPostchildNode"
            :props="PostProps"
            :expand-on-click-node="false"
            lazy
            :indent="30"
            @node-click="PostClick"
            highlight-current
            node-key="Id"
            ref="tree2"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <img
                  v-if="data.Type == '职务'"
                  src="../../assets/user.png"
                  alt
                />
                <img v-else src="../../assets/users.png" alt />
                {{ node.label }}
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </el-col>
    <!--人员展示列表-->
    <el-col :span="12">
      <div>
        <el-row>
          <ToolbarButtonGroupMini
            :buttonList="buttonListPostUser"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>人员</el-divider>
        <div class="postusertable">
          <el-table
            :data="users"
            highlight-current-row
            @current-change="selectCurrentRow"
            style="width: 100%"
            empty-text
          >
            <el-table-column type="index" width="40px"></el-table-column>
            <el-table-column
              label="账号"
              prop="UserName"
              width
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="用户名"
              prop="UserNameClaim"
              width
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="职务"
              prop="PostName"
              width
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination
          align="center"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :page-count="total"
          :total="totaldata"
        ></el-pagination>
      </div>
    </el-col>
    <el-dialog
      title="部门"
      :visible.sync="editDptVisible"
      :close-on-click-modal="false"
      v-model="editDptVisible"
    >
      <el-form
        :model="editDptForm"
        label-width="85px"
        :rules="editDptRules"
        ref="editDptForm"
      >
        <el-form-item label="部门编码:" prop="CascadeCode">
          <el-input
            v-model="editDptForm.CascadeCode"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门名称:" prop="Name">
          <el-input v-model="editDptForm.Name" :disabled="(dptVisibleState==1||dptVisibleState==2)?false:true"></el-input>
        </el-form-item>
        <el-form-item label="上级部门:">
          <el-input
                v-model="editDptForm.ParentName"
                :disabled="true"
              ></el-input>
        </el-form-item>
        <el-form-item label="部门级别:" prop="RankId">
          <el-select v-model="editDptForm.RankId" placeholder="请选择部门级别" :disabled="(dptVisibleState==1||dptVisibleState==2)?false:true">
            <el-option
              v-for="item in ParenRankIdModules"
              :key="item.Id"
              :value="item.Id"
              :label="item.Name"
              >{{ item.Name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="部门顺序:" prop="Sequence">
          <el-input v-model="editDptForm.Sequence" type="number" :disabled="(dptVisibleState==1||dptVisibleState==2)?false:true"></el-input>
        </el-form-item>
        <el-form-item label="部门类型:" prop="TypeId">
          <el-select v-model="editDptForm.TypeId" placeholder="请选择部门类型" :disabled="(dptVisibleState==1||dptVisibleState==2)?false:true">
            <el-option
              v-for="item in TypeIdModules"
              :key="item.Id"
              :value="item.Id"
              :label="item.Name"
              >{{ item.Name }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="部门描述:" prop="Description">
          <el-input
            v-model="editDptForm.Description"
            type="textarea"
            :disabled="(dptVisibleState==1||dptVisibleState==2)?false:true"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editDptSubmit" v-show="(dptVisibleState==1||dptVisibleState==2)?true:false">提交</el-button>
        </el-form-item>
      </el-form>
      <div v-show="!(dptVisibleState==1||dptVisibleState==2)?true:false">
        <el-divider>红头文件(双击查看)</el-divider>
<div class="calc-height">
          <el-table
        :data="redHeader"
      highlight-current-row
         @row-dblclick="redHeaderDptShow"
        height="100%"
     
        @current-change="selectRedCurrentRow"
        class="new_table"
      >
        <el-table-column label="字号" prop="OfficialNumber"></el-table-column>
        <el-table-column prop="NotifyName" label="通知名称"> </el-table-column>

        <el-table-column
          label="签批日期"
          prop="AgreementDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="公示日期"
          prop="PublicityDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="调整⽇期"
          prop="AdjustDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    
</div>
  <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleRedCurrentChange"
      :current-page="redcurrentPage"
      :page-size="redpageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="redtotal"
      :total="redtotaldata"
    >
    </el-pagination>
      </div>

    </el-dialog>
    <el-dialog
      title="职务"
      :visible.sync="editPostVisible"
      :close-on-click-modal="false"
      v-model="editPostVisible"
    >
        <el-form :model="editPostForm"
                 label-width="85px"
                 :rules="editPostRules"
                 ref="editPostForm">
            <el-form-item label="上级职务:">
                <el-row>
                    <el-col :span="22">
                        <el-input v-model="editPostForm.ParentName" :disabled="true"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="primary" size="mini" @click="choosePost" v-show="(postVisibleState==1||postVisibleState==2)?true:false">职务</el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="职务名称:" prop="Name">
                <el-input v-model="editPostForm.Name" :disabled="(postVisibleState==1||postVisibleState==2)?false:true"></el-input>
            </el-form-item>
            <el-form-item label="职务级别:" prop="RankId">
                <el-select v-model="editPostForm.RankId" placeholder="请选择职务级别" :disabled="(postVisibleState==1||postVisibleState==2)?false:true">
                    <el-option v-for="item in RankIdPostModules"
                               :key="item.Id"
                               :value="item.Id"
                               :label="item.Name">{{ item.Name }}</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="职务顺序:" prop="Sequence">
                <el-input v-model="editPostForm.Sequence" type="number" :disabled="(postVisibleState==1||postVisibleState==2)?false:true"></el-input>
            </el-form-item>
            <el-form-item label="职务类型:" prop="Type">
                <el-select v-model="editPostForm.Type"
                           placeholder="请选择职务类型"
                           :disabled="postVisibleState==1?false:true">
                    <el-option v-for="item in TypePostModules"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="工作类型:" prop="WorkTypeId">
                <el-select v-model="editPostForm.WorkTypeId"
                           placeholder="请选择工作类型"
                           :disabled="(postVisibleState==1||postVisibleState==2)?false:true">
                    <el-option v-for="item in WorkTypeIdPostModules"
                               :key="item.Id"
                               :value="item.Id"
                               :label="item.Name">{{ item.Name }}</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="接害岗位:" prop="Damaged">
                <el-switch v-model="editPostForm.Damaged"
                           active-text="是"
                           inactive-text="否" :disabled="(postVisibleState==1||postVisibleState==2)?false:true">
                </el-switch>
                
            </el-form-item>
            <el-form-item label="职务描述:" prop="Description">
                <el-input v-model="editPostForm.Description"
                          type="textarea"
                          :disabled="(postVisibleState==1||postVisibleState==2)?false:true"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="editPostSubmit" v-show="(postVisibleState==1||postVisibleState==2)?true:false">提交</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <!--人员列表编辑弹框-->
    <el-dialog
      title="用户职务"
      :visible.sync="editPostUserVisible"
      :close-on-click-modal="false"
      v-model="editPostUserVisible"
    >
      <div id="editUser">
        <el-table :data="postusers" highlight-current-row style="width: 100%">
          <el-table-column type="index" width="30px"></el-table-column>
          <el-table-column label="主职务" width="80px" prop="Principal">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.Principal ? 'success' : 'danger'"
                disable-transitions
                >{{ scope.row.Principal ? "是" : "否" }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="用户名"
            prop="UserName"
            width
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="职务"
            prop="PostName"
            width
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="部门"
            prop="DepartmentName"
            width
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="90px" v-if="postUserVisibleState==1?true:false">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="text"
                @click="resetPrincipal(scope.row)"
                >设为主职务</el-button
              >
            </template>
          </el-table-column>
        </el-table>
         <div v-show="!postUserVisibleState==1?true:false">
        <el-divider>红头文件(双击查看)</el-divider>
<div class="calc-height">
          <el-table
        :data="redUserHeader"
      highlight-current-row
         @row-dblclick="redUserHeaderDptShow"
        height="100%"
     
        @current-change="selectRedUserCurrentRow"
        class="new_table"
      >
        <el-table-column label="字号" prop="OfficialNumber"></el-table-column>
        <el-table-column prop="NotifyName" label="通知名称"> </el-table-column>

        <el-table-column
          label="签批日期"
          prop="AgreementDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="公示日期"
          prop="PublicityDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="调整⽇期"
          prop="AdjustDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    
</div>
  <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleRedUserCurrentChange"
      :current-page="redUsercurrentPage"
      :page-size="redUserpageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="redtUserotal"
      :total="redUsertotaldata"
    >
    </el-pagination>
      </div>
      </div>
    </el-dialog>
    <el-dialog
      title="选择职务"
      :visible.sync="postVisible"
      v-model="postVisible"
      :close-on-click-modal="false"
      center
    >
      <el-divider>职务</el-divider>
      <Postchoose
        :department="postParentDptId"
        @postcallFunction="choosePostCallBack"
      ></Postchoose>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      :close-on-click-modal="false"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :key="userchooseid"
        :all="true"
        :single="false"
        @callback="chooseUserCallBack"
      ></UserChoose>
    </el-dialog>
    <el-dialog
      title="查看"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label=" 字号:"
              prop="OfficialNumber"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.OfficialNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="通知名称:"
              prop="NotifyName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.NotifyName"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="签批日期:"
              prop="AgreementDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.AgreementDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公示日期:"
              prop="PublicityDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.PublicityDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调整⽇期:"
              prop="AdjustDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.AdjustDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="内容简述">
              <el-input type="textarea" v-model="SakaryForm.Sketch"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="SakaryForm.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="涉及组织" label-width="120px">
              <!-- <el-button @click="chooseUser()">选择涉及组织</el-button> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="SakaryForm.Orgs"
            style="width: 100%"
            class="bg-color"
          >
            <el-table-column prop="DptName" label="部门名称"></el-table-column>

            <!-- <el-table-column label="操作" width="150">
              <template scope="scope">
                <el-button
                  size="small"
                  v-on:click="PersonsDelete(scope.row)"
                  type="text"
                  >删除</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </el-row>
        <upload-files
          :files="SakaryForm.Files"
           :key="userchooseid"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          :IsDisabled="false"
          :IsDel="false"
        ></upload-files>
        <!-- <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
            <el-form-item label-width="0px">
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
    </el-dialog>
    <el-dialog
      title="查看"
      :visible.sync="SakaryUser"
      v-model="SakaryUser"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryUserForm"
        :model="SakaryUserForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label=" 字号:"
              prop="OfficialNumber"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryUserForm.OfficialNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="通知名称:"
              prop="NotifyName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryUserForm.NotifyName"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="签批日期:"
              prop="AgreementDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryUserForm.AgreementDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公示日期:"
              prop="PublicityDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryUserForm.PublicityDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调整⽇期:"
              prop="AdjustDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryUserForm.AdjustDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="内容简述">
              <el-input type="textarea" v-model="SakaryUserForm.Sketch"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="SakaryUserForm.Remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="涉及⼈员" label-width="120px">
            
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="SakaryUserForm.Persons"
            style="width: 100%"
            class="bg-color"
          >
            <el-table-column prop="UserName" label="姓名"></el-table-column>
            <el-table-column prop="Factory" label="公司"></el-table-column>
            <el-table-column prop="Dpt" label="部门"></el-table-column>
            <el-table-column prop="Post" label="职位"></el-table-column>
            <el-table-column prop="optType" label="类型"></el-table-column>
           
          </el-table>
        </el-row>
        <upload-files
          :files="SakaryUserForm.Files"
          :key="userchooseid"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          :IsDisabled="false"
          :IsDel="false"
        ></upload-files>
        <!-- <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row> -->
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import {
  GetChildrenList,
  GetDepartmentTypes,
  GetDepartmentRanks,
  GetManagePost,
  AddDept,
  Update,
  GetPostChildrenList,
  GetPostList,
  DeleteDept,
  GetPostTypes,
  GetWorkTypes,
  GetPost,
  AddPost,
  UpdatePost,
  DeletePost,
  GetPostUserList,
  GetUserPostList,
  GetDepartment,
  SetPrincipal,
  DeleteUser,
  BacthPostUsers,
  GetDepartmentList,
  GetDepartmentPostUserList,
} from "../../api/hr";
import util from "../../../util/date";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import ToolbarButtonGroupMini from "../../components/ToolbarButtonGroupMini";
import UserChoose from "../../components/UserChoose";
import Departmenttool from "../../components/Departmenttool";
import Postchoose from "../../components/Postchoose";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: {
    Toolbar,
    ToolbarButtonGroupMini,
    UserChoose,
    Departmenttool,
    Postchoose,
    UploadFiles
  },
  data() {
    return {
      SakaryUser:false,
       SakaryUserForm:[],
      redUserHeader:[],
       redUserpageSize: 15, // 每页的数据条数
      redUserpage: 1,
      redUsercurrentPage: 1, // 当前页码
      redtUserotal: null, // 总条数
      redUsertotaldata: null,
      redUsercurrentRow: null,
      SakaryVisible:false,
      SakaryForm:[],
      redHeader:[],
      redpageSize: 15, // 每页的数据条数
      redpage: 1,
      redcurrentPage: 1, // 当前页码
      redtotal: null, // 总条数
      redtotaldata: null,
      redcurrentRow: null,
      userchooseid: +new Date(),
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 15, // 每页的数据条数
      page: 1,
      choosedusers: [],
      dptVisibleState:0,  //部门维护状态 0查看 1新增 2编辑
      editDptVisible: false, //组织维护编辑是否显示
      postVisible: false, //选择职务弹框是否显示
      postVisibleState:0,  //职务维护状态 0查看 1新增 2编辑
      editPostVisible: false, //职务编辑弹框是否显示
      postUserVisibleState:0, //职务用户维护状态 0查看 1编辑主职务
      editPostUserVisible: false, //选中人员编辑弹框是否显示
      addUserVisible: false,
      ParenRankIdModules: [], //部门级别集合
      TypeIdModules: [], //部门类型集合
      RankIdPostModules: [], //职务级别集合
      WorkTypeIdPostModules: [], //工作类型集合
      TypePostModules: [], //职务类型集合
      postdata: [], //职务树绑定数据
      postSearch: null, //组织维护职务查询内容存放
      postsearchdata: [],
      departmentSearch: null,
      departmentsearchdata: [],
      users: [], //初始化人员展示列表
      currentRow: null, //初当前table选中行
      postParentDptId: null, //上级职务所在部门id
      buttonList: [],
      buttonListDpt: [],
      buttonListPost: [],
      buttonListPostUser: [],
      buttonListmsg: "请输入部门名称查询",
      postusers: [], //选中人员所有职务列表初始化
      editDptRules: {
        Name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        Sequence: [{ required: true, message: "请输入数字", trigger: "blur" }],
        RankId: [{ required: true, message: "请选择部门级别", trigger: "blur" }],
        TypeId: [{ required: true, message: "请选择部门类型", trigger: "blur" }],
      },
      editPostRules: {
        Name: [{ required: true, message: "请输入职务名称", trigger: "blur" }],
        Sequence: [{ required: true, message: "请输入职务顺序", trigger: "blur" }],
        Type: [{ required: true, message: "请选择职务类型", trigger: "blur" }],
        RankId: [{ required: true, message: "请选择职务级别", trigger: "blur" }],
        WorkTypeId: [{ required: true, message: "请选择工作类型", trigger: "blur" }],
      },
      //组织维护编辑表单初始化
      editDptForm: {
        Id: null,
        ParentId: null,
        CascadeCode: null,
        ParentName: null,
        Name: null,
        TypeId: null,
        RankId: null,
        Description: null,
        Sequence: null,
      },
      //组织职务编辑表单初始化
      editPostForm: {
        Id: null,
        ParentName: null,
        ParentId: null,
        Name: null,
        RankId: null,
        WorkTypeId: null,
        Sequence: null,
        Type: null,
        Description: null,
          DepartmentId: null,
          Damaged:null
      },
      DptProps: {
        label: "Name",
        isLeaf: "Leaf",
      },
      PostProps: {
        label: "Name",
        isLeaf: "Leaf",
      },
      TypePostModules: [
        {
          value: "职务",
          label: "职务",
        },
        {
          value: "职务工作组",
          label: "职务工作组",
        },
      ],
    };
  },
  methods: {
    //组织结构红头文件
     redHeaderDptShow() {
      let row = this.redcurrentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.$api.OfficialOrgInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.userchooseid = +new Date();
        this.SakaryVisible = true;
      });

     
    },
      //人员任免红头文件
     redUserHeaderDptShow() {
      let row = this.redUsercurrentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.$api.OfficialPersonInfo({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryUserForm = res.data.response;
        this.userchooseid = +new Date();
        this.SakaryUser = true;
      });

     
    },
    //toolbar查询
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
     formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //部门查询按钮
    departmentFind() {
      let para = { name: this.filters.name };
      GetDepartmentList(para).then((res) => {
        var myArray = new Array();
        for (var i = 0; i < res.data.response.length; i++) {
          myArray.push(res.data.response[i]);
        }
        this.departmentsearchdata = myArray;
        this.$refs.tree1.setCurrentKey(null);
      });
    },
    //组织维护加载节点的子节点集合
    loadDptChildNode(node, resolve) {
      let para = { departmentId: node.data.Id };
      GetChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //部门树节点渲染
    renderContent(h, { node, data, store }) {
      var icon = "";
      for (var i = 0; i < this.TypeIdModules.length; i++) {
        var tm = this.TypeIdModules[i];
        if (tm.Id == data.TypeId) {
          icon = tm.Icon;
          break;
        }
      }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      );
    },
    //懒加载刷新节点
    refreshLazyTree(node) {
      //非末级节点清空子节点
      if (!node.isLeaf) {
        var theChildren = node.childNodes;
        theChildren.splice(0, theChildren.length);
        // node.doCreateChildren(children);
      }
      node.loaded = false;
      node.expand();
    },
    //部门树点击
    dptTreeNodeClick(node) {
      this.users = [];
      let para = { departmentId: node.Id };
      GetManagePost(para).then((res) => {
        if (res.data.response != null) {
          var myArray = new Array();
          myArray[0] = res.data.response;
          this.postdata = myArray;
        } else {
          this.postdata = null;
        }
        this.$refs.tree2.setCurrentKey(null);
      });

      let dptpara = {
        departmentId: node.Id,
        page: this.page,
        size: this.pageSize,
      };
      GetDepartmentPostUserList(dptpara).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    //组织维护新增按钮事件
    addRole() {
      this.editDptVisible = true;
      this.dptVisibleState = 1;
      this.$nextTick(() => {
        this.$refs["editDptForm"].resetFields();
        let currentDptData = this.$refs.tree1.getCurrentNode();
        if (currentDptData) {
          this.editDptForm.ParentId = currentDptData.Id;
          this.editDptForm.ParentName = currentDptData.Name;
        }
      });
    },
    //组织维护新增中部门按钮事件
    chooseDept() {
    },
    //部门刷新
    refreshDptTreeNode() {
      //当前节点
      var currentdptid = this.$refs.tree1.getCurrentKey();
      if (currentdptid) {
        var currentnode = this.$refs.tree1.getNode(currentdptid);
        var pdtreeid = currentnode.data.ParentId;
        //新增并且不是叶子节点时
        if (this.dptVisibleState == 1 && !currentnode.isLeaf) {
          //不是叶子结点，重新加载当前节点的子节点
          this.refreshLazyTree(currentnode);
        } else {
          //刷新父节点
          //顶级节点或没有父节点
          if (currentnode.level == 1 || pdtreeid == undefined) {
            //全部重新加载
            let para = { departmentId: null };
            GetChildrenList().then((res) => {
              this.departmentsearchdata = res.data.response;
              this.$refs.tree1.setCurrentKey(null);
            });
          } else {
            //重新加载当前节点的父节点
            var pdnode = this.$refs.tree1.getNode(pdtreeid);
            this.refreshLazyTree(pdnode);
          }
        }
      } else {
        //全部重新加载
          let para = { departmentId: null };
          GetChildrenList().then((res) => {
            this.departmentsearchdata = res.data.response;
            this.$refs.tree1.setCurrentKey(null);
          });
      }
      //清除选择
      this.$refs.tree1.setCurrentKey(null);
    },
    //组织维护查看按钮事件
    showDept() {
      if(!this.$refs.tree1.getCurrentKey()){
        this.$message({
          message: "请选择要查看的部门！",
          type: "warning",
        });
        return;
      } 
     
      this.dptVisibleState=0;
       this.editDptVisible = true;
      this.$nextTick(() => {
        this.$refs["editDptForm"].resetFields();
        let currentDptData = this.$refs.tree1.getCurrentNode();
        if (currentDptData) {
          console.log(currentDptData)
     
          this.editDptForm = Object.assign({}, currentDptData);
          this.getRedGetDpt(currentDptData.Id);
        }
      });
    },
    getRedGetDpt(id){
     this.$api.OfficialOrgGetInfoList({orgId:id,page:this.redpage,size:this.redpageSize}).then((res) => {
                this.redHeader = res.data.response.data
                 this.redtotal = res.data.response.pageCount;
                 this.redtotaldata = res.data.response.dataCount;
                
              });
    },
    //组织维护编辑按钮事件
    editDept() {
      if(!this.$refs.tree1.getCurrentKey()){
        this.$message({
          message: "请选择要编辑的部门！",
          type: "warning",
        });
        return;
      } 
      this.editDptVisible = true;
      this.dptVisibleState=2;
      this.$nextTick(() => {
        this.$refs["editDptForm"].resetFields();
        let currentDptData = this.$refs.tree1.getCurrentNode();
        if (currentDptData) {
          this.editDptForm = Object.assign({}, currentDptData);
        }
      });
    },
    //组织维护编辑提交
    editDptSubmit() {
      this.$refs.editDptForm.validate((vaild) => {
        if (vaild) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
              let para = Object.assign({}, this.editDptForm);
              if(this.dptVisibleState==1){
                AddDept(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editDptForm"].resetFields();
                  this.editDptVisible = false;
                  this.refreshDptTreeNode();
                  this.dptVisibleState=0;
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
              }else if(this.dptVisibleState==2){
              Update(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editDptForm"].resetFields();
                  this.editDptVisible = false;
                  this.refreshDptTreeNode();
                  this.dptVisibleState=0;
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
              }
            });
        }
      });
    },
    //组织维护删除按钮事件
    deleteDept() {
      let currentDptId = this.$refs.tree1.getCurrentKey();
      if (currentDptId) {
        let currentDpt = this.$refs.tree1.getNode(currentDptId);
        console.info(currentDpt);
        this.$confirm("确认删除"+currentDpt.data.Name+"吗？", "提示", {}).then(() => {
          let para = { id: currentDptId };
          DeleteDept(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.refreshDptTreeNode();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        });
      }
    },
    //职务维护加载节点的子节点集合
    loadPostchildNode(node, resolve) {
      if (0 < node.level && node.isLeaf == false) {
        let para = { postId: node.data.Id };
        GetPostChildrenList(para).then((res) => {
          const roledata = res.data.response;
          resolve(roledata);
        });
      }
    },
    //职务新增按钮
    addPost() {
      this.editPostVisible = true;
      this.postVisibleState=1;
      this.$nextTick(() => {
        this.$refs["editPostForm"].resetFields();
        var departmentdata = this.$refs.tree1.getCurrentNode();
        if(!departmentdata)
        {
          this.$message({
            message: "请选择将职务建在哪个部门下!",
            type: "warning",
          });
          return;
        }
        if (this.postdata && 0 < this.postdata.length) {
            this.postParentDptId = departmentdata.Id;
            var currentpostdata = this.$refs.tree2.getCurrentNode();
            if (currentpostdata) {
              this.editPostForm.ParentId = currentpostdata.Id;
              this.editPostForm.ParentName = currentpostdata.Name;
            } else {
              this.editPostForm.ParentId = this.postdata[0].Id;
              this.editPostForm.ParentName = this.postdata[0].Name;
            }
          } else {
            this.postParentDptId = departmentdata.ParentId;
            let para = { departmentId: departmentdata.ParentId };
            GetManagePost(para).then((res) => {
              if (res.data.response != null) {
                this.editPostForm.ParentId = res.data.response.Id;
                this.editPostForm.ParentName = res.data.response.Name;
              }
            });
          }
          this.editPostForm.DepartmentId = departmentdata.Id;
      });
    },
    ////组织维护职务选择按钮事件
    choosePost() {
      this.postVisible = true;
    },
    //职务子组件监听
    choosePostCallBack(node) {
      this.editPostForm.ParentName = node.Name;
      this.editPostForm.ParentId = node.Id;
      this.postVisible = false;
    },
    //职务刷新
    refreshPostTreeNode() {
      let departmentId = this.$refs.tree1.getCurrentKey();
      if (departmentId) {
        //当前节点
        var currentid = this.$refs.tree2.getCurrentKey();
        if (currentid) {
          var currentnode = this.$refs.tree2.getNode(currentid);
          var treepid = currentnode.data.ParentId;
          //新增并且不是叶子节点时
          if (this.postVisibleState == 1 && !currentnode.isLeaf) {
            //不是叶子结点，重新加载当前节点的子节点
            this.refreshLazyTree(currentnode);
          } else {
            //刷新父节点
            //顶级节点或没有父节点
            if (currentnode.level == 1 || treepid == undefined) {
              let para = { departmentId: departmentId };
              GetManagePost(para).then((res) => {
                if (res.data.response != null) {
                  var myArray = new Array();
                  myArray[0] = res.data.response;
                  this.postdata = myArray;
                }
              });
            } else {
              //重新加载当前节点的父节点
              var pdnode = this.$refs.tree2.getNode(treepid);
              this.refreshLazyTree(pdnode);
            }
          }
        } else {
          //全部重新加载
          let para = { departmentId: departmentId };
          GetManagePost(para).then((res) => {
            if (res.data.response != null) {
              var myArray = new Array();
              myArray[0] = res.data.response;
              this.postdata = myArray;
            }
          });
        }
        //清除选择
        this.$refs.tree2.setCurrentKey(null);
      }
    },
    //职务查看
    showPost(){
      var currentpostid = this.$refs.tree2.getCurrentKey();
      if(!currentpostid){
        this.$message({
            message: "请选择要查看的职务!",
            type: "warning",
          });
          return;
      }
      this.editPostVisible = true;
        this.postVisibleState=0;
        this.$nextTick(() => {
          this.$refs["editPostForm"].resetFields();
          var currentPostNode = this.$refs.tree2.getNode(currentpostid);
          if (currentPostNode.level == 1) {
            let currentDptId = this.$refs.tree1.getCurrentKey();
            if (currentDptId) {
              let currentDptNode = this.$refs.tree1.getNode(currentDptId);
              this.postParentDptId = currentDptNode.data.ParentId;
            }
          } else {
            if (currentPostNode.data) {
              this.postParentDptId = currentPostNode.data.DepartmentId;
            }
          }
          this.editPostForm = Object.assign({}, currentPostNode.data);
        });
    },
    //职务编辑
    editPost() {
      var currentpostid = this.$refs.tree2.getCurrentKey();
      if(!currentpostid){
        this.$message({
            message: "请选择要编辑的职务!",
            type: "warning",
          });
          return;
      }
      this.editPostVisible = true;
        this.postVisibleState=2;
        this.$nextTick(() => {
          this.$refs["editPostForm"].resetFields();
          var currentPostNode = this.$refs.tree2.getNode(currentpostid);
          if (currentPostNode.level == 1) {
            let currentDptId = this.$refs.tree1.getCurrentKey();
            if (currentDptId) {
              let currentDptNode = this.$refs.tree1.getNode(currentDptId);
              this.postParentDptId = currentDptNode.data.ParentId;
            }
          } else {
            if (currentPostNode.data) {
              this.postParentDptId = currentPostNode.data.DepartmentId;
            }
          }
          this.editPostForm = Object.assign({}, currentPostNode.data);
        });
    },
    //编辑职务提交
    editPostSubmit() {
      this.$refs.editPostForm.validate((vaild) => {
        if (vaild) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
              let para = Object.assign({}, this.editPostForm);
              if(this.postVisibleState==1){
                AddPost(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editPostForm"].resetFields();
                  this.editPostVisible = false;
                  this.refreshPostTreeNode();
                  this.postVisibleState=0;
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
              }else if(this.postVisibleState==2){
              UpdatePost(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editPostForm"].resetFields();
                  this.editPostVisible = false;
                  this.refreshPostTreeNode();
                  this.postVisibleState=0;
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
              }
            });
        }
      });
    },
    //删除职务
    deletePost() {
      let currentPostId = this.$refs.tree2.getCurrentKey();
      if (currentPostId) {
        let currentNode = this.$refs.tree2.getNode(currentPostId);
        if(!currentNode.isLeaf){
          this.$message({
              message: "该职务有子职务，不能直接删除!",
              type: "error",
            });
          return;
        }
        this.$alert("确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              let para = { id: currentPostId };
              DeletePost(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.refreshPostTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            },
          });
      }
    },
    //职务树形结构节点点击
    PostClick(node) {
      this.page = 1;
      this.choosedusers = [];
      let para = {
        postId: node.Id,
        page: this.page,
        size: this.pageSize,
      };
      GetPostUserList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        if (this.users.length == 0) {
          this.choosedusers = [];
        } else {
          var myArray = new Array();
          for (var i = 0; i < this.users.length; i++) {
            this.choosedusers.push(this.users[i].UserId);
          }
        }
      });
    },
    //获取人员列表下一页
    getNextUsers() {
      let currentPostData = this.$refs.tree2.getCurrentNode();
      let currentDptData = this.$refs.tree1.getCurrentNode();
      if (currentPostData && currentPostData.Id) {
        let para = {
          postId: currentPostData.Id,
          page: this.page,
          size: this.pageSize,
        };
        GetPostUserList(para).then((res) => {
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          this.users = res.data.response.data;
        });
      } else if (currentDptData && currentDptData.Id) {
        let dptpara = {
          departmentId: currentDptData.Id,
          page: this.page,
          size: this.pageSize,
        };
        GetDepartmentPostUserList(dptpara).then((res) => {
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          this.users = res.data.response.data;
        });
      }
    },
    //翻页触发
    handleCurrentChange(val) {
      this.page = val;
      this.getNextUsers();
      this.page = 1;
    },
      //红头文件翻页触发
    handleRedCurrentChange(val) {
      this.redpage = val;
      this.getRedGetDpt();
    },
    handleRedUserCurrentChange(val) {
      this.redUserpage = val;
      this.getRedUser();
    },
    //获取每行row信息
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    selectRedCurrentRow(val){
       this.redcurrentRow = val;
    },
    selectRedUserCurrentRow(val){
       this.redUsercurrentRow = val;
    },
    //人员删除
    deleteUser() {
      let row = this.currentRow;
      let posid = row.PostId;
      let usid = row.UserId;
      let para = {
        postId: posid,
        userId: usid,
      };
      this.$alert("是否删除!", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            this.choosedusers = [];
            DeleteUser(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                let currentPostData = this.$refs.tree2.getCurrentNode();
                if (currentPostData && currentPostData.Id) {
                  let callbackpara = {
                    postId: currentPostData.Id,
                    page: 1,
                    size: this.pageSize,
                  };
                  GetPostUserList(callbackpara).then((res) => {
                    this.users = res.data.response.data;
                    var myArray = new Array();
                    for (var i = 0; i < this.users.length; i++) {
                      this.choosedusers.push(this.users[i].UserId);
                    }
                  });
                }
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          }
        },
      });
    },
    //人员列表查看
    showPostUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "warning",
        });
        return;
      }
      this.editPostUserVisible = true;
      this.postUserVisibleState=0;
      let para = { userId: row.UserId };
      GetUserPostList(para).then((res) => {
        this.postusers = res.data.response;
      });
      this.getRedUser(row.UserId)
    },
    getRedUser(id){

  this.$api.OfficialPersonGetInfoList({userId:id,intPageIndex:this.redUserpage,intPageSize:this.redUserpageSize}).then((res) => {
                this.redUserHeader = res.data.response.data
                 this.redUsertotal = res.data.response.pageCount;
                 this.redUsertotaldata = res.data.response.dataCount;
                
              });
    },
    //人员列表编辑
    handlePostUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.editPostUserVisible = true;
      this.postUserVisibleState=1;
      let para = { userId: row.UserId };
      GetUserPostList(para).then((res) => {
        this.postusers = res.data.response;
      });
    },
    //设为主职务
    resetPrincipal(row) {
      this.$alert("是否设为主职务", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          let para = { id: row.Id };
          SetPrincipal(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.editPostUserVisible = false;
              this.postUserVisibleState=0;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        },
      });
    },
    //选择人员按钮点击
    chooseUser() {
      if (this.$refs.tree2.getCurrentKey()) {
        this.addUserVisible = true;
      }
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
      console.info(data);
      var newdata = data.map(function (item) {
        return item["Id"];
      });
      let currentPostData = this.$refs.tree2.getCurrentNode();
      if (currentPostData) {
        let c = newdata.filter((i) => !this.choosedusers.includes(i));
        let d = this.choosedusers.filter((j) => !newdata.includes(j));
        let para = {
          addModels: [],
          updateModels: [],
          deleteIds: [],
        };
        if (c.length != 0) {
          var addArray = [];
          for (var i = 0; i < c.length; i++) {
            addArray.push({ postId: currentPostData.Id, userId: c[i] });
          }
          para.addModels = addArray;
        }
        if (d.length != 0) {
          var deleteArray = [];
          for (var i = 0; i < this.users.length; i++) {
            if (-1 < d.indexOf(this.users[i].UserId)) {
              deleteArray.push(this.users[i].Id);
            }
          }
          para.deleteIds = deleteArray;
        }
        if (c.length != 0 || d.length != 0) {
          BacthPostUsers(para).then((res) => {
            this.choosedusers = [];
            if (res.data.success) {
              this.addUserVisible = false;
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              let callbackpara = {
                postId: currentPostData.Id,
                page: 1,
                size: this.pageSize,
              };
              GetPostUserList(callbackpara).then((res) => {
                this.users = res.data.response.data;
                var myArray = new Array();
                for (var i = 0; i < this.users.length; i++) {
                  this.choosedusers.push(this.users[i].UserId);
                }
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      }
    },
  },
  watch: {
    addUserVisible(val) {
      this.userchooseid = +new Date();
    },
  },
  mounted() {
    GetDepartmentTypes().then((res) => {
      this.TypeIdModules = res.data.response.data;
    });
    GetDepartmentRanks().then((res) => {
      this.ParenRankIdModules = res.data.response.data;
    });
    GetPostTypes().then((res) => {
      this.RankIdPostModules = res.data.response.data;
    });
    GetWorkTypes().then((res) => {
      this.WorkTypeIdPostModules = res.data.response.data;
    });
    // this.getPermissions();
    // getPermissionTree().then((res) => {
    //   this.options.push(res.data.response);
    // });
    // this.getModules();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    // //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];

    var allButtons = getButtonList(this.$route.path, routers, buttons);
    allButtons.forEach((element) => {
      if (
        element.Func &&
        (element.Func.toLowerCase() == "showDept".toLowerCase() ||
        element.Func.toLowerCase() == "addRole".toLowerCase() ||
          element.Func.toLowerCase() == "editDept".toLowerCase() ||
          element.Func.toLowerCase() == "deleteDept".toLowerCase())
      ) {
        this.buttonListDpt.push(element);
      } else if (
        element.Func &&
        (element.Func.toLowerCase() == "showPost".toLowerCase() ||
        element.Func.toLowerCase() == "addPost".toLowerCase() ||
          element.Func.toLowerCase() == "editPost".toLowerCase() ||
          element.Func.toLowerCase() == "deletePost".toLowerCase())
      ) {
        this.buttonListPost.push(element);
      } else if (
        element.Func &&
        (element.Func.toLowerCase() == "showPostUser".toLowerCase() ||
        element.Func.toLowerCase() == "deleteUser".toLowerCase() ||
          element.Func.toLowerCase() == "handlePostUser".toLowerCase() ||
          element.Func.toLowerCase() == "chooseUser".toLowerCase())
      ) {
        this.buttonListPostUser.push(element);
      } else {
        this.buttonList.push(element);
      }
    });
  },
};
</script>
<style scoped>
.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url("../../assets/add.png") !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url("../../assets/cancel.png") !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: url("../../assets/blank.png") !important;
  font-size: 16px;
}

.el-form-item .el-input {
  width: 100%;
}

.el-form-item .el-select {
  width: 100%;
}

.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
.orgtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 280px);
  /*width:200px;*/
  border: 0px solid blue;
}
.postusertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 300px);
  /*width:200px;*/
  border: 0px solid blue;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
}
.container .main {
  background-color: #ffffff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* position: absolute; */
  top: 60px;
  bottom: 0px;
  overflow: hidden;
}
</style>